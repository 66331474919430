<template>
  <div class="print" style="overflow-y: hidden;">
    <div class="pageContainer" style="margin-bottom:200px; overflow-y: hidden; overflow-x: hidden;">
      <div class="mt-1" style="text-align:center"><h5 style="padding-top:3px;">Sale Tax Report</h5></div>
      <div class="mt-1" v-if="offices.length>0"><h6 style="padding-top:3px;"><strong>Offices:</strong> {{offices.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="clients.length>0"><h6 style="padding-top:3px;"><strong>Clients:</strong> {{clients.map(item=>{return item.name}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="statusClients.length>0"><h6 style="padding-top:3px;"><strong>Client Status:</strong> {{statusClients.map(item=>{return item.status}).join(", ")}}</h6></div>
      <div class="mt-1" v-if="status.length>0"><h6 style="padding-top:3px;"><strong>Status:</strong> {{status.join(", ")}}</h6></div>
      <div class="mt-1" v-if="years.length>0"><h6 style="padding-top:3px;"><strong>Years:</strong> {{years.join(", ")}}</h6></div>
      <div class="mt-1" v-if="quarters.length>0"><h6 style="padding-top:3px;"><strong>Quarters:</strong> {{quarters.join(", ")}}</h6></div>
      <div class="mt-1" v-if="states.length>0"><h6 style="padding-top:3px;"><strong>States:</strong> {{states.map(item=>{return item.name}).join(", ")}}</h6></div>

      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding" style="margin-bottom:3px"> 

          <table class="table-sm table table-border m-0 pt-1">
            <thead>
              <tr>
                <th scope="col">Client</th>
                <th scope="col">Form</th>
                <th scope="col">Quarter</th>
                <th scope="col">Due Date</th>
                <th scope="col">Assigned To</th>
                <th scope="col">Gross Sales</th>
                <th scope="col">Non - Taxable</th>
                <th scope="col">Net Tax Due</th>
                <th scope="col">Tax Payment</th>
                <th scope="col">Processed</th>
                <th scope="col">Processed By</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-bind:key="tax.id" v-for="tax in tableData">
                <td class="bt" style="font-weight: bold; color:#0e5499">{{tax.client.clientName}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{tax.form?tax.form.name:''}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ tax.year + " " + tax.quarter }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ tax.dueDate | moment("MM-DD-YYYY") }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ tax.client.responsible.name }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">$ {{ new Intl.NumberFormat().format(tax.grossSales) }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">$ {{ new Intl.NumberFormat().format(tax.nonTaxable) }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">$ {{ new Intl.NumberFormat().format(tax.NetTaxableSales) }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">$ {{ new Intl.NumberFormat().format(tax.paymentAmount) }}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{tax.processed}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{tax.responsible.name}}</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{ tax.processedDate | moment("MM-DD-YYYY") }}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import saleTax from "@/services/api/saleTax";

export default {
  data() {
    return {
      data:[],    
      tableData: [],
      offices: [],
      statusClients: [],
      clients: [],
      status: [],
      years: [],
      quarters: [],
      states :[]
    };
  },
  mounted() {
    this.offices=JSON.parse(this.$route.query.offices);
    this.statusClients=JSON.parse(this.$route.query.statusClients);
    this.clients=JSON.parse(this.$route.query.clients);
    this.status=JSON.parse(this.$route.query.status);
    this.years=JSON.parse(this.$route.query.years);
    this.quarters=JSON.parse(this.$route.query.quarters);
    this.states=JSON.parse(this.$route.query.states);
    saleTax.get().then((response) => {
      this.data = Object.assign([], response);
      this.tableData = response;
      this.filterData()
    });

    this.download();
  },
  methods: {
    download() {
      this.fullscreenLoading = false;
      setTimeout(() => {
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    },
    filterData(){

      let idOffices = this.offices.map(office=>office.id);
      let clients = this.clients.map(client=>client.uuid);
      let statusClients = this.statusClients.map(status=>status.value);
      let status = this.status;
      let years = this.years.map(year=>year.toString());
      let quarters = this.quarters;
      let states = this.states.map(state=>state.state);

      
      console.log(idOffices)
      console.log(clients)
      console.log(statusClients)
      console.log(status)
      console.log(years)
      console.log(quarters)
      console.log(states)
      this.tableData = Object.assign([], this.data);

      if (idOffices.length > 0) {
        this.tableData = this.tableData.filter(
          (item) =>
            idOffices.indexOf(item.client.responsible.office_user.office) != -1
        );
      }

      if (clients.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clients.indexOf(item.client.uuid) != -1
        );
      }

      if (status.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => status.indexOf(item.processed) != -1
        );
      }

      if (years.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => years.indexOf(item.year) != -1
        );
      }

      if (quarters.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => quarters.indexOf(item.quarter) != -1
        );
      }
      if (states.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => states.indexOf(item.form.stateId) != -1
        );
      }
    }
  }
};
</script>

<style lang="scss">

.pageContainer{
  margin-left: 30px;
  margin-right: 30px;
}

.pages{
  
}

table {
  border-collapse: separate; /* the default option */
  border-spacing: 0; /* remove border gaps */
}

th,td{
  font-size: 11px;
}


tr {
   line-height: 10px;
   min-height: 10px;
   height: 10px;
}


.bt {
  border-top: 1px dotted black !important;
}

.bb{
  border-bottom: 1px dotted black !important;
}

td{
  font-size: 9px;
}

.border-padding{
  padding-top: 2px;
  padding-bottom: 2px;
}


@page 
    {
        size: auto;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
        page-break-after: always !important;
        
    }



</style>